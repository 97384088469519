export default (httpClient) => {
  const uploadFile = (formData) =>
    httpClient.post('/files/validations', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  return {
    uploadFile,
  }
}
