import axios from 'axios'

const client = ($config) =>
  axios.create({
    baseURL: $config.baseURL,
    withCredentials: false,
    headers: {
      'Accept-Language': $config.acceptLanguage,
    },
    validateStatus: (status) => status >= 100 && status <= 499,
  })

export default client
