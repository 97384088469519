import Vue from "vue";
import {
  ValidationProvider,
  extend,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
