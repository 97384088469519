
export default {
  data(vm) {
    return {
      fixed: true,
      miniVariant: false,
      items: [
        {
          icon: "mdi-home",
          title: "Home",
          to: "/",
        },
        {
          icon: "mdi-file",
          title: vm.$t("sidebar.validation"),
          to: "/validacao",
        },
      ],
    };
  },
  methods: {
    handleAppBarClick() {
      this.miniVariant = !this.miniVariant;
      this.fixed = !this.fixed;
    },

    handleMouseOver() {
      if (this.miniVariant) {
        this.miniVariant = false;
      }
    },

    handleMouseLeave() {
      if (!this.fixed) {
        this.miniVariant = true;
      }
    },
  },

  computed: {
    subheaderClasses() {
      return {
        "justify-space-around": !this.miniVariant,
        "justify-start": this.miniVariant,
      };
    },

    listClasses() {
      return {
        "pa-4": !this.miniVariant,
      };
    },
  },
};
