export const LayoutsDefaultBaseAlertNotification = () => import('../../components/layouts/default/base-alert-notification.vue' /* webpackChunkName: "components/layouts-default-base-alert-notification" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseDatatable = () => import('../../components/layouts/default/base-datatable.vue' /* webpackChunkName: "components/layouts-default-base-datatable" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseDownloadButton = () => import('../../components/layouts/default/base-download-button.vue' /* webpackChunkName: "components/layouts-default-base-download-button" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseDropzone = () => import('../../components/layouts/default/base-dropzone.vue' /* webpackChunkName: "components/layouts-default-base-dropzone" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseExpansionPanel = () => import('../../components/layouts/default/base-expansion-panel.vue' /* webpackChunkName: "components/layouts-default-base-expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseFooter = () => import('../../components/layouts/default/base-footer.vue' /* webpackChunkName: "components/layouts-default-base-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseSidebar = () => import('../../components/layouts/default/base-sidebar.vue' /* webpackChunkName: "components/layouts-default-base-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizardFirstPage = () => import('../../components/layouts/default/wizard/first-page.vue' /* webpackChunkName: "components/layouts-default-wizard-first-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizard = () => import('../../components/layouts/default/wizard/index.vue' /* webpackChunkName: "components/layouts-default-wizard" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizardLastPage = () => import('../../components/layouts/default/wizard/last-page.vue' /* webpackChunkName: "components/layouts-default-wizard-last-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizardSecondPage = () => import('../../components/layouts/default/wizard/second-page.vue' /* webpackChunkName: "components/layouts-default-wizard-second-page" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
